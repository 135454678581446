import PropTypes from 'prop-types';
import { useMemo } from 'react';
import web from 'src/common/constants/analytics/categoryActionLabel/web';
import { LIBRARY } from 'src/common/constants/localizations/header';
import { SEARCH_CAPITALIZED } from 'src/common/constants/localizations/search';
import * as textKeys from 'src/common/constants/localizations/shared';
import {
  AUDIOBOOKS_BROWSE_PATH,
  MAP_VIEW,
  PODCASTS_BROWSE_PATH,
  SEARCH_PATH,
} from 'src/common/constants/paths';
import cssVars from 'src/common/styles/variables';
import { getRadioPathname } from 'src/common/utils/guideItem/getGuideItemPathname';
import pathsToGuideIdsData from 'src/data/rootCategories.json';
import { AUDIOBOOKS_ENABLED } from '../../constants/experiments/audiobooks';
import { MAP_VIEW_ENABLED } from '../../constants/experiments/mapView';
import useExperiment from '../../hooks/useExperiment';
import isUserInUSOrUSTerritories from '../../utils/isUserInUSOrUSTerritories';
import getUserDataUrl from '../../utils/paths/getUserDataUrl';
import AudiobooksIcon from '../shared/svgIcons/AudiobooksIcon';
import ExplorerIcon from '../shared/svgIcons/ExplorerIcon';
import HomeIcon from '../shared/svgIcons/HomeIcon';
import IHeartIcon from '../shared/svgIcons/IHeartIcon';
import LanguageIcon from '../shared/svgIcons/LanguageIcon';
import LibraryIcon from '../shared/svgIcons/LibraryIcon';
import LocationIcon from '../shared/svgIcons/LocationIcon';
import MusicIcon from '../shared/svgIcons/MusicIcon';
import NewsIcon from '../shared/svgIcons/NewsIcon';
import PodcastIcon from '../shared/svgIcons/PodcastIcon';
import PremiumIcon from '../shared/svgIcons/PremiumIcon';
import RadioIcon from '../shared/svgIcons/RadioIcon';
import RecentsIcon from '../shared/svgIcons/RecentsIcon';
import SearchIcon from '../shared/svgIcons/SearchIcon';
import SportsIcon from '../shared/svgIcons/SportsIcon';
import TrendingIcon from '../shared/svgIcons/TrendingIcon';
import NavigationMenuItem from './NavigationMenuItem';

const { pathsToGuideIds } = pathsToGuideIdsData;

const { leftNav: leftNavLabels } = web.subLabels;

const iconProps = {
  fill: cssVars['--cloud-grey'],
  width: cssVars['--large-icon-size'],
  height: cssVars['--large-icon-size'],
};

const NavigationMenu = ({
  iHeartGuideId,
  isAuthenticated,
  userLocationCountry,
  selectedGuideId,
  subscriptionEnabled,
  onMenuItemClick,
  userDetails,
  pathname,
}) => {
  const isAudiobooksEnabled = useExperiment(AUDIOBOOKS_ENABLED, false);
  const isMapViewEnabled = useExperiment(MAP_VIEW_ENABLED, false);
  const isUserInUS = isUserInUSOrUSTerritories(userLocationCountry);

  const navigationItems = useMemo(
    () =>
      [
        {
          guideId: pathsToGuideIds.home,
          dataTestId: 'home',
          path: getRadioPathname('home'),
          textKey: textKeys.HOME,
          iconComponent: <HomeIcon {...iconProps} />,
        },
        {
          guideId: leftNavLabels.search,
          dataTestId: 'search',
          path: SEARCH_PATH,
          textKey: SEARCH_CAPITALIZED,
          iconComponent: <SearchIcon {...iconProps} />,
        },
        {
          guideId: pathsToGuideIds.premium,
          dataTestId: 'premium',
          path: getRadioPathname('premium'),
          textKey: textKeys.PREMIUM,
          iconComponent: <PremiumIcon {...iconProps} />,
        },
        isAudiobooksEnabled && {
          guideId: pathsToGuideIds.audiobooks,
          dataTestId: 'audiobooks',
          path: AUDIOBOOKS_BROWSE_PATH,
          textKey: textKeys.AUDIOBOOKS,
          iconComponent: <AudiobooksIcon {...iconProps} />,
        },
        isMapViewEnabled && {
          dataTestId: 'mapView',
          path: MAP_VIEW,
          textKey: textKeys.MAP_VIEW,
          iconComponent: <ExplorerIcon {...iconProps} />,
        },
        !isMapViewEnabled && {
          guideId: pathsToGuideIds.local,
          dataTestId: 'local',
          path: getRadioPathname('local'),
          textKey: textKeys.LOCAL_RADIO,
          iconComponent: <RadioIcon {...iconProps} />,
        },
        {
          guideId: pathsToGuideIds.recents,
          dataTestId: 'recents',
          path: getRadioPathname('recents'),
          textKey: textKeys.RECENTS,
          iconComponent: <RecentsIcon {...iconProps} />,
        },
        iHeartGuideId &&
          isUserInUS && {
            guideId: iHeartGuideId,
            dataTestId: 'iheart',
            path: getRadioPathname(`Stream-iHeartRadio-${iHeartGuideId}`),
            textKey: textKeys.IHEART,
            iconComponent: <IHeartIcon {...iconProps} />,
          },
        !isMapViewEnabled && {
          guideId: pathsToGuideIds.trending,
          dataTestId: 'trending',
          path: getRadioPathname('trending'),
          textKey: textKeys.TRENDING,
          iconComponent: <TrendingIcon {...iconProps} />,
        },
        {
          guideId: pathsToGuideIds.music,
          dataTestId: 'music',
          path: getRadioPathname('music'),
          textKey: textKeys.MUSIC,
          iconComponent: <MusicIcon {...iconProps} />,
        },
        {
          guideId: pathsToGuideIds.sports,
          dataTestId: 'sports',
          path: getRadioPathname('sports'),
          textKey: textKeys.SPORTS,
          iconComponent: <SportsIcon {...iconProps} />,
        },
        {
          guideId: pathsToGuideIds.news,
          dataTestId: 'news',
          path: getRadioPathname('Live-Stream-News-Radio-c57922'),
          textKey: textKeys.NEWS_AND_TALK,
          iconComponent: <NewsIcon {...iconProps} />,
        },
        {
          guideId: pathsToGuideIds.podcasts,
          dataTestId: 'podcasts',
          path: PODCASTS_BROWSE_PATH,
          textKey: textKeys.PODCASTS,
          iconComponent: <PodcastIcon {...iconProps} />,
        },
        {
          guideId: pathsToGuideIds.regions,
          dataTestId: 'regions',
          path: getRadioPathname('regions'),
          textKey: textKeys.BY_LOCATION,
          iconComponent: <LocationIcon {...iconProps} />,
        },
        {
          guideId: pathsToGuideIds.languages,
          dataTestId: 'languages',
          path: getRadioPathname('languages'),
          textKey: textKeys.BY_LANGUAGE,
          iconComponent: <LanguageIcon {...iconProps} />,
        },
        isAuthenticated &&
          userDetails && {
            guideId: leftNavLabels.favorites,
            dataTestId: 'favorites',
            path: getUserDataUrl(userDetails),
            textKey: LIBRARY,
            iconComponent: <LibraryIcon {...iconProps} />,
          },
      ].filter(Boolean),
    [
      isAudiobooksEnabled,
      isMapViewEnabled,
      iHeartGuideId,
      isUserInUS,
      isAuthenticated,
      userDetails,
    ],
  );

  return (
    <div id="sidebarNavLinks">
      {navigationItems.map((navItem) => {
        const isSelected =
          (selectedGuideId && selectedGuideId === navItem.guideId) ||
          pathname === navItem.path;

        return (
          <NavigationMenuItem
            isSelected={isSelected}
            item={navItem}
            key={`${navItem.path}.${isSelected}`}
            subscriptionEnabled={subscriptionEnabled}
            onClick={onMenuItemClick}
          />
        );
      })}
    </div>
  );
};

NavigationMenu.propTypes = {
  userDetails: PropTypes.shape({
    userName: PropTypes.string,
  }),
  iHeartGuideId: PropTypes.string,
  userLocationCountry: PropTypes.string,
  selectedGuideId: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  subscriptionEnabled: PropTypes.bool.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  getLocalizedText: PropTypes.func.isRequired,
};

export default NavigationMenu;
