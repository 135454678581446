import classNames from 'clsx';
import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';
import Drawer from 'material-ui/Drawer';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { IHEART_GUIDE_ID } from 'src/common/constants/affiliates/iHeartRadio';
import {
  selectBottomAdBannerHeight,
  selectIsDesktop,
  selectTopBannerHeight,
} from 'src/common/selectors/app';
import { selectShouldShowDesktopUpdateBanner } from 'src/common/selectors/desktop';
import {
  selectIsExpiredWebSubscriber,
  selectIsUserSubscribed,
} from 'src/common/selectors/me';
import { logWebActivity } from '../../actions/logging';
import { labels } from '../../constants/analytics/categoryActionLabel/subscribe';
import web from '../../constants/analytics/categoryActionLabel/web';
import { LEFT_SIDEBAR_FIXED_BOTTOM_MENU } from '../../constants/experiments/navigation';
import connectWithAuth from '../../decorators/auth/connectWithAuth';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectExperiment } from '../../selectors/config';
import cssVariables from '../../styles/variables';
import getTopBannerOffsetStyles from '../../utils/banner/getTopBannerOffsetStyles';
import { getCssStyle } from '../../utils/getCssStyle';
import { appendToLabel } from '../../utils/logging';
import shouldShowPlayer from '../../utils/playerStatus/shouldShowPlayer';
import getSubscribeEventLabel from '../../utils/subscription/getSubscribeEventLabel';
import Scrollbar from '../Scrollbar';
import TuneInMarquee from '../shared/svgIcons/TuneInMarquee';
import UpsellButton from '../subscription/UpsellButton';
import BottomMenu from './BottomMenu';
import NavigationMenu from './NavigationMenu';
import css from './leftSide.module.scss';

const drawerStyle = {
  top: 0,
  backgroundColor: cssVariables['--content-area-background-color-hex'],
  boxShadow: 'none',
  zIndex: cssVariables['--app-z-index'],
  overflow: 'hidden',
};
const overlayStyle = {
  cursor: 'pointer',
  backgroundColor: 'rgba(11, 14, 36, 0.5)',
  zIndex: cssVariables['--app-z-index'],
};
const leftSideWidth = cssVariables['--leftSide-width'];

export class LeftSidebar extends Component {
  static propTypes = {
    userLocation: PropTypes.string,
    isLeftSideOpen: PropTypes.bool,
    isLeftSideDocked: PropTypes.bool,
    iHeartGuideId: PropTypes.string.isRequired,
    handleLeftSideToggle: PropTypes.func,
    breakpoint: PropTypes.number.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    currentGuideId: PropTypes.string,
    subscriptionEnabled: PropTypes.bool.isRequired,
    authActions: PropTypes.shape({
      logout: PropTypes.func.isRequired,
      needsSignInDialog: PropTypes.func.isRequired,
      needsSignUpDialog: PropTypes.func.isRequired,
    }),
    actions: PropTypes.shape({
      logWebActivity: PropTypes.func.isRequired,
    }),
    userDetails: PropTypes.shape({
      userName: PropTypes.string,
    }),
    history: PropTypes.object.isRequired,
    isUserSubscribed: PropTypes.bool.isRequired,
    isExpiredWebSubscriber: PropTypes.bool.isRequired,
    shouldShowDesktopUpdateBanner: PropTypes.bool,
    topBannerHeight: PropTypes.number,
    isPlayerVisible: PropTypes.bool.isRequired,
    isFixedBottomMenuEnabled: PropTypes.bool.isRequired,
    gdprApplies: PropTypes.bool,
    bottomAdBannerHeight: PropTypes.number,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.topSectionRef = createRef();
    this.bottomMenuRef = createRef();

    this.debouncedUpdateNavAndBottomMenuUi = debounce(
      () => this.updateNavAndBottomMenuUi(),
      150,
    );

    this.state = {
      isScrollShadowVisible: false,
      navStyles: {},
    };
  }

  componentDidMount() {
    this.updateNavAndBottomMenuUi();
    window.addEventListener('resize', this.debouncedUpdateNavAndBottomMenuUi);
  }

  componentDidUpdate(prevProps) {
    const {
      isLeftSideOpen,
      gdprApplies,
      isPlayerVisible,
      bottomAdBannerHeight,
    } = this.props;

    if (
      isLeftSideOpen !== prevProps.isLeftSideOpen ||
      (gdprApplies && !prevProps.gdprApplies) ||
      isPlayerVisible !== prevProps.isPlayerVisible ||
      bottomAdBannerHeight !== prevProps.bottomAdBannerHeight
    ) {
      this.updateNavAndBottomMenuUi(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.debouncedUpdateNavAndBottomMenuUi,
    );
  }

  getIsScrollShadowVisible(prevIsScrollShadowVisible) {
    if (!this.props.isFixedBottomMenuEnabled) {
      return false;
    }

    const scrollHeightOffset = prevIsScrollShadowVisible ? 16 : 0;
    const topSectionEl = this.topSectionRef.current;
    return (
      topSectionEl?.scrollHeight - scrollHeightOffset >
      topSectionEl?.clientHeight
    );
  }

  getNavStyles() {
    const { isPlayerVisible, isFixedBottomMenuEnabled } = this.props;

    const baseOffset = isFixedBottomMenuEnabled ? '0px' : '60px';
    const playerHeight = getCssStyle('--player-height');
    const playerHeightOffset = isPlayerVisible ? playerHeight : '0px';

    return {
      paddingBottom: `calc(${baseOffset} + ${playerHeightOffset})`,
    };
  }

  /**
   * Updates the scroll shadow flag for the bottom menu and nav styles.
   * Due to the effect of asynchronous UI updates triggered by the changing prop values in componentDidUpdate(),
   * refs have stale values until the call stack is cleared. By leveraging setState()'s callback argument,
   * we can call updateNavAndBottomMenuUi() a second time to perform another calculation pass with the updated ref
   * values. This is a more reliable approach than setTimeout, as performance differs from machine to machine,
   * creating a race condition or forcing us to arbitrarily select a relatively high timeout.
   *
   * @param {bool} callTwice - forces updateNavAndBottomMenuUi() to be called a second time
   */
  updateNavAndBottomMenuUi = (callTwice = false) => {
    this.setState(
      (prevState) => ({
        isScrollShadowVisible: this.getIsScrollShadowVisible(
          prevState.isScrollShadowVisible,
        ),
        navStyles: this.getNavStyles(),
      }),
      () => callTwice && this.updateNavAndBottomMenuUi(),
    );
  };

  onMenuItemClick = (subLabel) => {
    this.props.actions.logWebActivity(
      web.actions.tap,
      appendToLabel(web.labels.leftNav, subLabel),
    );
  };

  render() {
    const {
      isDesktop,
      subscriptionEnabled,
      isUserSubscribed,
      isExpiredWebSubscriber,
      userLocationCountry,
      iHeartGuideId,
      isLeftSideOpen,
      isLeftSideDocked,
      handleLeftSideToggle,
      authActions,
      isAuthenticated,
      breakpoint,
      currentGuideId,
      shouldShowDesktopUpdateBanner,
      topBannerHeight,
      history,
      isFixedBottomMenuEnabled,
      userDetails,
    } = this.props;
    const { getLocalizedText, location } = this.context;
    const { navStyles, isScrollShadowVisible } = this.state;

    if (shouldShowDesktopUpdateBanner) {
      Object.assign(drawerStyle, getTopBannerOffsetStyles(topBannerHeight));
    }

    const eventLabel = getSubscribeEventLabel(
      labels.leftsidenavUpsellDialog,
      location,
    );
    const showUpsell = subscriptionEnabled && !isUserSubscribed;

    return (
      <Drawer
        width={leftSideWidth}
        docked={isLeftSideDocked}
        open={isLeftSideOpen}
        onRequestChange={handleLeftSideToggle}
        containerStyle={drawerStyle}
        overlayStyle={overlayStyle}
      >
        <Scrollbar width={leftSideWidth}>
          <nav
            id="sidebarNav"
            data-testid="sidebarNav"
            className={classNames(css.nav, {
              [css.flexContainer]: isFixedBottomMenuEnabled,
              [css.isDesktop]: isDesktop,
            })}
            style={navStyles}
          >
            <div
              ref={this.topSectionRef}
              className={classNames(css.topSection, {
                [css.bottomPadding]: isScrollShadowVisible,
              })}
            >
              {!isDesktop && (
                <Link id="tuneInLogo" to="/" className={css.marqueeContainer}>
                  <TuneInMarquee
                    width="89px"
                    height="39px"
                    fill={cssVariables['--secondary-color-5']}
                  />
                </Link>
              )}
              {showUpsell && (
                <div
                  id="sidebarUpsellButtonContainer"
                  data-testid="sidebarUpsellButtonContainer"
                  className={css.upsellButtonContainer}
                >
                  <UpsellButton
                    dataTestId="sidebarUpsellLink"
                    isExpiredWebSubscriber={isExpiredWebSubscriber}
                    source={eventLabel}
                    guideId={currentGuideId}
                  />
                </div>
              )}
              <NavigationMenu
                iHeartGuideId={iHeartGuideId}
                isAuthenticated={isAuthenticated}
                selectedGuideId={currentGuideId}
                subscriptionEnabled={subscriptionEnabled}
                onMenuItemClick={this.onMenuItemClick}
                pathname={location.pathname}
                getLocalizedText={getLocalizedText}
                userDetails={userDetails}
                userLocationCountry={userLocationCountry}
              />
            </div>
            <BottomMenu
              ref={this.bottomMenuRef}
              history={history}
              getLocalizedText={getLocalizedText}
              location={location}
              breakpoint={breakpoint}
              isDesktop={isDesktop}
              isAuthenticated={isAuthenticated}
              isScrollShadowVisible={isScrollShadowVisible}
              isFixedBottomMenuEnabled={isFixedBottomMenuEnabled}
              isLeftSideOpen={isLeftSideOpen}
              authActions={authActions}
              onMenuItemClick={this.onMenuItemClick}
            />
          </nav>
        </Scrollbar>
      </Drawer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        logWebActivity,
      },
      dispatch,
    ),
  };
}

function mapStateToProps(state) {
  const { config, me } = state;

  return {
    userDetails: me?.details,
    userLocationCountry: config?.location?.country_code,
    iHeartGuideId: selectExperiment(state, IHEART_GUIDE_ID),
    isDesktop: selectIsDesktop(state),
    isUserSubscribed: selectIsUserSubscribed(state),
    isExpiredWebSubscriber: selectIsExpiredWebSubscriber(state),
    shouldShowDesktopUpdateBanner: selectShouldShowDesktopUpdateBanner(state),
    topBannerHeight: selectTopBannerHeight(state),
    isPlayerVisible: shouldShowPlayer(state),
    isFixedBottomMenuEnabled: selectExperiment(
      state,
      LEFT_SIDEBAR_FIXED_BOTTOM_MENU,
    ),
    bottomAdBannerHeight: selectBottomAdBannerHeight(state),
  };
}

export default flowRight(
  connect(mapStateToProps, mapDispatchToProps),
  connectWithAuth,
)(LeftSidebar);
